export const AsiaLinq = [
  {
    growth: "47+",
    description: "A Team With Over 100+ Years of Financial Experience"
  },
  {
    growth: "233+",
    description: "Over 500 Points of Dataare Analyzed"
  },
  {
    growth: "61K",
    description: "We Aim To Connect Over 130,000 SMEs to Capital"
  }
];
