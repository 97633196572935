import { useEffect, useRef, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import FadeUp from "components/FadeUp";
import bannerImage from "assets/images/amazonSeller/LLSBanner.png";
import Arrow from "constant/arrow";
import bannerArrow from "assets/images/tikTokShop/tikTokBannerArrow.svg";
// import usa from "assets/images/tikTokShop/Usa.svg";
import indonesia from "assets/images/tikTokShop/Indonesia.svg";
import singapore from "assets/images/tikTokShop/Singapore .svg";
import others from "assets/images/tikTokShop/Others.svg";
import cashBackWeb from "assets/images/tikTokShop/amazonwebBg.png";
import cashBackMobile from "assets/images/tikTokShop/cashBack.png";
import amazonSellerArrow from "assets/images/tikTokShop/amazonSellerArrow.svg";
import amazonGift from "assets/images/tikTokShop/modalGiftImage.svg";
import closeButton from "assets/images/tikTokShop/offerCloseIcon.svg";
import "components/Banner/banner.scss";
import useScreenMobile from "hooks/useScreenMobile";

const options = [
  //   { country: "United States", flag: usa },
  { country: "Indonesia", flag: indonesia },
  { country: "Singapore", flag: singapore },
  { country: "Other", flag: others }
];

const Banner = ({
  bannerData,
  extraClass,
  extraPara,
  childClass,
  secondClass,
  aboutClass,
  gradientClass,
  dynamicData,
  childData,
  newClass,
  amazonSellerBannerData,
  payLaterDirectRemoveTextTransform,
  amazonSellerBannerParaData,
  amazonSellerBannerImage,
  amazonSellerSpecialText,
  amazonSellerClickingText
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [country, setCountry] = useState({ country: "", flag: "" });

  const [showModal, setShowModal] = useState(false);
  const [showSFModal, setShowSFModal] = useState(false);

  const isTab = useScreenMobile({ size: 768 });

  const divRef = useRef(null);
  const location = useLocation();

  const isAboutPage = location.pathname === "/about-us";
  const isAmazonSeller = location.pathname === "/seller-financing";
  const isCLIISAmazonSeller = location.pathname === "/cl-lls/seller-financing";
  const isTic = location.pathname === "/tiktok-shop-seller-financing";
  const isPayLaterDirect = location.pathname === "/pay-later-direct";

  const handleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleDropdownClose = (index) => {
    setCountry({
      ...country,
      country: options[index]["country"],
      flag: options[index]["flag"]
    });
    setShowDropdown(false);
  };

  const handleNavigation = () => {
    if (country.country === "Other") {
      window.open("https://tiktokshop.credilinq.ai/", "_blank");
    } else if (country.country === "Indonesia") {
      window.open(
        "https://seller-id.tokopedia.com/account/register?redirect_url=https%3A%2F%2Fseller-id.tokopedia.com%2Fservices%2Fmarket%2Fservice-detail%2F7384703337218836229",
        "_blank"
      );
    } else if (country.country === "United States") {
      window.open(
        "https://seller-us.tiktok.com/services/market/service-detail/7384703337218836229",
        "_blank"
      );
    } else {
      window.open(
        "https://seller-sg.tiktok.com/account/register?redirect_url=https%3A%2F%2Fseller-sg.tiktok.com%2Fservices%2Fmarket%2Fservice-detail%2F7384703337218836229",
        "_blank"
      );
    }
  };

  const handleRedirect = () => {
    window.open(
      "https://sellercentral.amazon.com/selling-partner-appstore/dp/amzn1.sp.solution.8929c9a5-7836-49fc-9a92-53ea94c71151",
      "_blank"
    );
  };

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [showModal]);

  //   useEffect(() => {
  //     setCountry({ country: "", flag: "" });
  //   }, []);

  return (
    <section className="commonBackground bannerData">
      <div className="container">
        <FadeUp>
          <div className="bannerDataGrouping">
            <div className={"bannerDataContent " + amazonSellerBannerData}>
              <h1
                className={
                  "commonBannerHeading " +
                  childClass +
                  " " +
                  extraClass +
                  " " +
                  aboutClass +
                  " " +
                  payLaterDirectRemoveTextTransform
                }
              >
                {bannerData?.title}
                <label
                  className={
                    "commonBannerGradient " +
                    secondClass +
                    " " +
                    gradientClass +
                    " " +
                    dynamicData
                  }
                >
                  {bannerData?.gradientText}
                </label>
              </h1>
              <span
                style={{ paddingRight: "10px" }}
                className={"amazonSellerSpecialText"}
              >
                {/* SIMPLE  |  FAST  |  FLEXIBLE */}
                {bannerData.specialText}
              </span>
              <span
                className={
                  "bannerDataParaGraph " +
                  extraPara +
                  " " +
                  amazonSellerBannerParaData
                }
              >
                {bannerData.paraGraph}
              </span>
              {!isAboutPage && (
                <>
                  {(isAmazonSeller || isPayLaterDirect) && (
                    <Link to={bannerData.link} target="_blank">
                      <button className="commonButton">
                        {bannerData.button} <Arrow />
                      </button>
                    </Link>
                  )}
                  {isCLIISAmazonSeller && (
                    <>
                      <div
                        onClick={() => {
                          setShowSFModal(true);
                        }}
                      >
                        <button className="commonButton">
                          {bannerData.button} <Arrow />
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
              {/* {!isAboutPage && isAmazonSeller && (
                <Link to={bannerData.link} target="_blank">
                  <button className="commonButton">
                    {bannerData.button} <Arrow />
                  </button>
                </Link>
              )} */}
              {isTic && (
                <div className="shopByCountry">
                  <p>
                    Select Country for your TikTok Shop and{isTab && <br />}{" "}
                    click
                    <span> &apos;Apply Now&apos; </span>
                  </p>
                  <div ref={divRef} className="inputButtonBox">
                    <div className="select">
                      <div className="inputArrow" onClick={handleDropdown}>
                        <span>
                          <img src={country.flag} alt="" />
                        </span>
                        <input
                          readOnly
                          value={country.country}
                          placeholder="TikTok Shop registered in"
                        />
                        <div className="arrowBox">
                          <img src={bannerArrow} alt="arr" />
                        </div>
                      </div>
                      {showDropdown && (
                        <div className="options">
                          {options.map((itm, index) => (
                            <div
                              className="option"
                              onClick={() => handleDropdownClose(index)}
                              key={index}
                            >
                              <img src={itm.flag} alt="" />
                              <span>{itm.country}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <button
                      onClick={handleNavigation}
                      disabled={!country.country}
                      className={`commonButton ${
                        !country.country ? "disabled" : ""
                      }`}
                    >
                      {bannerData.button} <Arrow />
                    </button>
                  </div>
                </div>
              )}
              {isCLIISAmazonSeller && (
                <span style={{ marginTop: "10px", fontSize: "12px" }}>
                  {" "}
                  <span style={{ color: "#9B3581" }}>*</span> By clicking this,
                  you will be redirected to Amazon Seller Central.
                </span>
              )}

              <span
                style={{ visibility: country.country ? "visible" : "hidden" }}
                className={"amazonSellerClickingText"}
              >
                {country.country === "Other"
                  ? bannerData.clickingTextOfOther
                  : bannerData.clickingText}
              </span>
              {/* <span style={{ paddingTop: "10px" }}>
                {bannerData?.clickingText}
              </span> */}
            </div>
            {/* </FadeUp> */}
            {/* <FadeUp> */}
            <div
              className={
                "bannerDataImage " +
                childData +
                " " +
                newClass +
                " " +
                amazonSellerBannerImage
              }
            >
              {/* {!isAmazonSeller && (
                <img src={bannerData?.bannerImg} alt="bannerImage" />
              )} */}
              {(isAmazonSeller || isTic) && (
                <div className="amazonsellerBox">
                  <div className="giftBox">
                    <img
                      src={isTab ? cashBackMobile : cashBackWeb}
                      alt="amazonImageBanner"
                    />
                  </div>
                  <div className="contentBox">
                    <div className="promoCode">
                      Use Promo : {isTic ? "TTSAUG24" : "AMZAUG24"}
                    </div>
                    {!isTic && (
                      <button onClick={handleRedirect}>
                        Apply Now <img src={amazonSellerArrow} alt="" />
                      </button>
                    )}
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowModal(true)}
                      className="term"
                    >
                      Terms & Conditions applicable
                    </p>
                  </div>
                </div>
              )}
              {isCLIISAmazonSeller && (
                <div className="bannerLogo">
                  <img src={bannerImage} alt="bannerLogo" />
                </div>
              )}
            </div>
          </div>
        </FadeUp>
        {showModal && (
          <div className="offerModal">
            <div className="modal">
              <div className="modalTop">
                <span>Valid till August 30 2024</span>
                <p>
                  Apply and use the facility in August 2024 & get a US$100
                  refund
                </p>
                <img
                  onClick={() => setShowModal(false)}
                  src={closeButton}
                  alt=""
                />
              </div>
              <div className="modalInfo">
                <div className="infoImagWrapper">
                  <ul>
                    <li>
                      For new sellers who use promo code –{" "}
                      <span>{isTic ? "TTSAUG24" : "AMZAUG24"} </span>
                    </li>
                    <li>
                      First loan has to be greater than or equal to US$15,000
                      and in August 2024
                    </li>
                    <li>
                      Need to make timely repayment on or before due date for
                      1st drawdown
                    </li>
                    <li>
                      Sellers become ineligible if they miss the repayment date
                    </li>
                    <li>Rebate to be given on service fees for 2nd drawdown</li>
                    <li>Rebate is valid only for the 2nd drawdown</li>
                    <li>Cannot combine multiple drawdowns</li>
                    <li>
                      The rebate would be in same the same currency as the
                      drawdown
                    </li>
                    <li>Rebate would offset some of the service fee</li>
                    <li>
                      Applicable for the first 20 customer who apply and take a
                      US$10,000 loan within the faciity
                    </li>
                    <li>
                      Promotions can be revoked at the company &apos;s
                      discretion
                    </li>
                    <li>All disputes to be decided by CrediLinq.</li>
                  </ul>
                  {!isTab && (
                    <div className="imgBox">
                      <img src={amazonGift} alt="" />
                    </div>
                  )}
                </div>
                {!isTic && (
                  <button
                    onClick={handleRedirect}
                    className="commonButton offerButton"
                  >
                    Apply Now <Arrow />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {showSFModal && (
          <>
            <div className="offerModal alertModal">
              <div className="modal">
                <div className="modalTop">
                  <p>Redirection Alert</p>
                  <img
                    onClick={() => setShowSFModal(false)}
                    src={closeButton}
                    alt=""
                  />
                </div>
                <div className="modalInfo">
                  <div>
                    <p>
                      You will be now redirected to CrediLinq.A.I Pte Ltd. eco
                      system to complete your onboarding with LinkLogis.
                    </p>
                    <p>
                      CrediLinq.A.I Pte Ltd. is a technology partner of
                      LinkLogis.
                    </p>
                  </div>

                  <Link
                    onClick={() => setShowSFModal(false)}
                    to={bannerData.link}
                    target="_blank"
                  >
                    {" "}
                    <button className="commonButton offerButton">
                      Apply Now <Arrow />
                    </button>
                  </Link>
                  <span style={{ fontSize: "12px" }}>
                    {" "}
                    <span style={{ color: "#9B3581" }}>*</span> By clicking
                    this, you will be redirected to Amazon Seller Central.
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Banner;
